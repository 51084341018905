// GLOBAL VARIABLES
// ===========================================================

  // ==========[ GLOBALE KLEUR VOOR URLS ]==========
    $global-link-color         : #1e87f0;
    $global-link-hover-color   : #1e87f0;

  // ==========[ GLOBALE KLEUREN ]==========
    $global-background         : #ffffff;
    $global-primary-background : #1e87f0;
    $global-success-background : #32d296;
    $global-warning-background : #faa05a;
    $global-danger-background  : #f0506e;

  // ==========[ DEFAULT FONT FAMILY ]==========
    $global-font-family        : 'proxima-nova', 'Helvetica', 'Arial', sans-serif;

  // ==========[ GLOBAL MARGINS ]==========
    $global-large-margin       : 100px;
    $global-medium-margin      : 40px;
    $global-small-margin       : 10px;

  // ==========[ GLOBAL BORDERS ]==========
    $global-border-width       : 1px;
    $global-border             : #e5e5e5;

// OFF CANVAS NAV VARIABLES
// ===========================================================

  // ==========[ OFFCANVAS NAV ACHTERGROND KLEUR ]==========
    $offcanvas-bar-background  : #fff;

  // ==========[ KLEUR INHOUD VAN DE OFFCANVAS NAV (UK-LIGHT, UK-DARK, NONE) ]==========
    $offcanvas-bar-color-mode  : none;

// CONTAINERS
// ===========================================================

  // ==========[ CONTAINER BREEDTES ]==========
  $container-max-width         : 1200px;
  $container-xsmall-max-width  : 750px;
  $container-small-max-width   : 900px;
  $container-large-max-width   : 1600px;

// GRID
// ===========================================================

  // zet gutter uikit gelijk aan gutter bootstrap 
  $grid-gutter-horizontal: 30px;
  $grid-gutter-horizontal-l: 30px;
  $grid-gutter-vertical: 30px;
  $grid-gutter-vertical-l: 30px;